import { createBrowserRouter } from "react-router-dom";
import Authmiddleware from "./middleware/Authmiddleware";
import NonAuthLayout from "../routes/middleware/NonAuthLayout";
import Logout from "../pages/Authentication/Logout";
import Login from "../pages/Authentication/Login";
import ResetPassword from "../pages/Authentication/ResetPassword";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Dashboard from "../pages/Dashboard";

import UserManagement from "../pages/User-Management";
import KYB_Submit from "../pages/Kyb-Dashboard";
import Inquiry from "../pages/Inquiry";
import ContactRequest from "../pages/ContactRequest";
import GlobalAccount from "../pages/GlobalAccount";

const routes = createBrowserRouter([
  //public routes

  {
    path: "/logout",
    element: (
      <NonAuthLayout>
        <Logout />{" "}
      </NonAuthLayout>
    ),
    exact: true,
  },
  {
    path: "/login",
    element: (
      <NonAuthLayout>
        <Login />{" "}
      </NonAuthLayout>
    ),
    exact: true,
  },
  {
    path: "/forgot-password",
    element: (
      <NonAuthLayout>
        <ForgetPasswordPage />
      </NonAuthLayout>
    ),
    exact: true,
  },
  {
    path: "/reset-password",
    element: (
      <NonAuthLayout>
        <ResetPassword />
      </NonAuthLayout>
    ),
    exact: true,
  },
 



  //Private routes
  {
    path: "/",
    element: (
      <Authmiddleware>
        <p>sss</p>
      </Authmiddleware>
    ),
    exact: true,
  },
  { path: "/dashboard", element: <Authmiddleware><Dashboard /></Authmiddleware>, exact : true},
  
 
  //  KYB Dashboard
  { path: "/kyb-dashboard", element: <Authmiddleware><KYB_Submit /></Authmiddleware> },

  //Glbal Account
  { path: "/global-account", element: <Authmiddleware><GlobalAccount /></Authmiddleware> },


  //user management
  { path: "/user-management", element: <Authmiddleware><UserManagement /></Authmiddleware> },

  //  Inquiry
  { path: "/inquiry", element: <Authmiddleware><Inquiry /></Authmiddleware> },

  //Contact Request
  { path: "/contact-request", element: <Authmiddleware><ContactRequest /></Authmiddleware> },


]);

export default routes;
