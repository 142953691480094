import axios from "axios"

//get Token From local storage
const token = JSON.parse(localStorage.getItem("backOfficetoken"))
const refreshToken = JSON.parse(localStorage.getItem("backOfficeRefreshToken"))

const airwallexToken = ""
const airwallexObBehalfToken = ""



//apply base url for axios
const API_URL = process.env.REACT_APP_BASE_URL;
const AIRWALLEX_URL = "https://api-demo.airwallex.com/api/v1/";

const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    "traceId" : 123,
    "Authorization" : `${token}`,
    "allowedHeaders": "*"
  }
})



  const axiosApiAirwallex = axios.create({
    baseURL: AIRWALLEX_URL,
    headers: {
      "Content-Type": "application/json",
      "x-client-id" : "LeFefhmNT_WGEAoDordrjQ", //globle tps id
      "x-api-key" : "fbab0bb27a1b0b444cc043e5fc8cc5d7c197aac939aca57f8e67dbd47acafaadfaf57526397f225029b0c34db8f26521", 
      'x-on-behalf-of':`${airwallexObBehalfToken}`,
      "Authorization" : `Bearer ${airwallexToken}`
    }
  })








// axiosApi.interceptors.request.use(
//   (config) => {
//     if (token) {
//       // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
//       config.headers["Authorization"] =  "Bearer eyJhbGciOiJIUzI1NiJ9.eyJ0eXBlIjoiY2xpZW50IiwiZGMiOiJISyIsImRhdGFfY2VudGVyX3JlZ2lvbiI6IkhLIiwiaXNzZGMiOiJVUyIsImp0aSI6IjkwMzJhMjU4LTM3MDQtNDJkMy1iYTA5LTFhYTJlZTA1Y2YyNiIsInN1YiI6IjJkZTE1ZTdlLTE5OGQtNGZmNS04NjEwLTBhMDNhMmI3NmI4ZCIsImlhdCI6MTY5OTYxMTM5MSwiZXhwIjoxNjk5NjEzMTkxLCJhY2NvdW50X2lkIjoiYmZlZDRkOTAtZjk1OC00OWJjLWJhZDAtNmU0OTcwN2M0NWVmIiwicGVybWlzc2lvbnMiOlsicjphd3g6KjoqIiwidzphd3g6KjoqIl19.d334dWwXdM2LpivAeOxZP5s8cHrcU68WfcUD6uNuLDY";

//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// )


axiosApi.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/login" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await axiosApi.post("/auth/refreshtoken", {
            refreshToken: refreshToken,
          });

          const { accessToken } = rs.data;
          localStorage.setItem("backOfficetoken", JSON.stringify(accessToken));

          return axiosApi(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);


export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}, token ) {
  return axiosApi
    .post(url, { ...data },  {  ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}



//AirWallex Post

export async function postAirwallex(url, data, config = {} ) {
  return axiosApiAirwallex
    .post(url, { ...data },  {  ...config })
    .then(response => response.data)
}

export default axiosApi